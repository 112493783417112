import React from "react";
import SvgIconsSet from "../components/SvgIconsSet";
import { Container } from "reactstrap";

const Casestudy = (props) => {
  return (
    <div className="wrap-case-study mt-3">
      <Container>
        <div className="row py-6">
          <div className="col-12">
            <div className="career">
              <div className="">
                <div className="row justify-content-center">
                  <div className="col-12 col-lg-6 flex-column">
                    <div>
                      <h5 className="title-5">Case Study</h5>
                      <h3 className="title-3">{props.title}</h3>
                      <p>{props.text}</p>
                    </div>
                    <a href={props.download} download class="btn btn-primary btn-icon-button mt-2 mb-3">
                      <span className="btn-text">Download Case Study</span>
                      <span className="btn-icon">
                        <SvgIconsSet.SideArrow />
                      </span>
                    </a>
                  </div>
                  <div className="col-12 col-lg-6">
                    <img alt="product innovation" className="img-fluid" src={props.image} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container> 
    </div>
  );
};

export default Casestudy;
