import React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../../../layouts/index";
import Seo from "../../../components/Seo";
import IntroBanner from "../../../components/IntroBannerRevamp";
import Banner from "../../../images/services/data/banner.jpg";
import ContrastSection from "../../../components/ContrastSection";

import { Container } from "reactstrap";
import Img from "gatsby-image";
import SvgIconsSet from "../../../components/SvgIconsSet";

//Accordian
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import plus from "../../../images/icons/plus.svg";
import minus from "../../../images/icons/minus.svg";

//Images
import BannerAi from "../../../images/services/data/banner-ai.png";
import BannerAi1 from "../../../images/services/data/banner-ai1.jpg";
import BannerApi from "../../../images/services/data/banner-api.png";
import BannerCrm from "../../../images/services/data/banner-crm.jpg";

//Process
import OurProcess from "../../../components/services/OurProcess";

//Casestudy
import caseStudyImg from "../../../images/Enterprise-Integration.jpg";
import downloadFile from '../../../documents/Case-Study-AI-Lead-Distribution.pdf' 
import Casestudy from "../../../components/Casestudy";

//Contact
import GetInTouchbtn from "../../../components/GetInTouchbtn";
import BookConsultation from "../../../components/BookConsultation";
import ConsultationBanner from "../../../images/consultation.jpg";

const DataAnalyticsAI = (props) => {
  return (
    <Layout bodyClass="page-services">
      <Seo
        keyword="Data Analytics & AI"
        title="Data Analytics & AI"
        description="Innovation Partner - IoT, FinTech, Mobile Apps & Software Development, Cloud Engineering | iTelaSoft"
      />
      {/* <div className="intro intro-banner">
        <div className="intro-banner-img" id="up">
          <Img
            fluid={props.data.bannerImg.childImageSharp.fluid}
            loading="eager"
            fadeIn={true}
            alt="Banner Image"
            className="hero-banner"
          />
        </div>
        <div className="container">
          <div className="row hero-section">
            <div className="col-12 wrap-page-title">
              <h1 className="page-title">Data Analytics &amp; AI</h1>
              <hr />
            </div>
          </div>
        </div>
      </div> */}
      <IntroBanner
        title="Data Analytics &amp; AI"
        subtitle="Unearth deep data insights with innovative AI solutions"
        image={Banner}
      />
      {/* <ContrastSection
        title="Unearth deep data insights with innovative AI solutions"
        para={["Lorem ipsum dolor sit amet consectoli tur adipiscing elit semper dalar. Lorem ipsum dolor sit amet consectoli tur adipiscing elit semper dalar. Lorem ipsum dolor sit amet consectoli tur adipiscing elit semper dalar. Lorem ipsum dolor sit amet consectoli tur adipiscing elit semper dalar."]}
      /> */}
      <div className="container pb-6 section-2">
        <div className="row pt-6 mb-3">
          <div className="col-12">
            <h2 className="title-1">Our Services</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 pr-md-5">
            <img alt="product innovation" className="img-fluid" src={BannerAi} />
          </div>
          <div className="col-md-6 pt-3 pt-md-0 d-flex flex-column justify-content-center">
            <h2 className="title-2">Leverage AI in existing systems & processes</h2>
            <p>
              {/* <strong>Leverage AI in existing systems & processes.</strong> */}
              Improvement doesn’t mean having to start from scratch, and leveraging AI enables you to discover new business insights, increase efficiency of complex processes, drive innovation and gain a competitive advantage. We can design and apply AI capabilities to suit your needs, and walk you through initial design, through the learning phase, and finally its practical application.  
            </p>
          </div>
        </div>
        <div className="row py-6">
          <div className="col-md-6 pr-md-5 order-md-2">
            <img alt="product innovation" className="img-fluid" src={BannerCrm} />
          </div>
          <div className="col-md-6 pt-3 pt-md-0 d-flex flex-column justify-content-center">
            <h2 className="title-2">Enhance data-driven decision making </h2>
            <p>
              {/* <strong>Streamline operations and enhance data-driven decision making. </strong>
              <br/> */}
              Data doesn’t always equal clear insight into what your data is trying to tell you. We help you extract data insights from your CRM or ERP to improve the management of customer relationships, your internal processes and resource management. Use these extractable data insights for informed decision-making, increase efficiency and improve customer retention. 
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 pr-md-5">
            <img alt="product innovation" className="img-fluid" src={BannerAi1} />
          </div>
          <div className="col-md-6 pt-3 pt-md-0 d-flex flex-column justify-content-center">
            <h2 className="title-2">Streamline operations & efficiency </h2>
            <p>
              {/* <strong>Leverage AI in existing systems & processes.</strong> */}
              Simplify and speed up repetitive tasks to make operations more efficient and cost-effective. Leveraging AI capabilities enables you to reduce the risk of human error, create additional capacity in your teams, maintain accuracy and produce auditable reports with ease.
            </p>
          </div>
        </div>
        <div className="row py-6">
          <div className="col-md-6 pr-md-5 order-md-2">
            <img alt="product innovation" className="img-fluid" src={BannerApi} />
          </div>
          <div className="col-md-6 pt-3 pt-md-0 d-flex flex-column justify-content-center">
            <h2 className="title-2">Enhance Business Intelligence </h2>
            <p>
              {/* <strong>Streamline operations and enhance data-driven decision making. </strong>
              <br/> */}
              Our team are experts in the sophisticated engineering of interconnecting devices and systems to increase process efficiency and unearth deep business intelligence. We can connect data across systems and teams to ensure you have a line of sight over the ‘bigger picture’ and the ability to identify potential new opportunities.  
            </p>
          </div>
        </div>
        <div className="getting-touch"><GetInTouchbtn textdce={'Free consultation'} /></div>
      </div>
      <Container>
        <OurProcess />
      </Container>

      <section>
        <Casestudy
          image = {caseStudyImg}
          title ="HOW ARTIFICIAL INTELLIGENCE LED TO A 40% REDUCTION IN CUSTOMER RESPONSE TIME"
          text = "We share how mortgage broking clients were able to increase lead management efficiency by 30% using a smart application of artificial intelligence."
          download = {downloadFile}
        />
      </section>

      <Container className="my-6">
        <h2 className="title-1 mb-3">Book a free consultation</h2>
        <BookConsultation image={ConsultationBanner} />
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query {
    bannerImg: file(relativePath: { eq: "cloud-services-1024x597.png" }) {
      childImageSharp {
        fluid(maxWidth: 4000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
export default DataAnalyticsAI;
